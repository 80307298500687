import React from "react";
import { Mention, MentionsInput } from "react-mentions";
import type { Tag, Mention as TagMention } from "~/types";

const defaultStyle = {
  "&multiLine": {
    control: {
      fontFamily: "inherit",
      minHeight: 40,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      border: "0",
      outline: "none",
    },
  },

  suggestions: {
    list: {
      borderRadius: "12px",
      border: "2px solid #000",
      background: "#FFF",
      boxShadow: "4px 4px 0px 0px #000",
    },
    item: {
      borderRadius: "10px",
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",

      "&focused": {
        backgroundColor: "#FFA9DE",
      },
    },
  },
};

interface TagInputProps {
  value: string;
  onChange: (event: any, ref: any) => void;
  onAddTag?: (id: string | number) => void;
  tags: Tag[];
  mentions: TagMention[];
  placeholder: string;
  className?: string;
}

const TagInput = (props: TagInputProps) => {
  const tagInputRef = React.useRef<HTMLInputElement>(null);
  const tagData = props.tags?.map((tag: Tag) => ({
    id: tag.id,
    display: tag.name,
  }));

  const mentionData = props.mentions?.map((mention) => ({
    id: mention.id,
    display: `${mention.text}`,
    slug: mention.slug,
  }));

  const displayTransform = (id: string, display: string) => {
    const regex = /(.+?) \((.+?)\)/;
    const match = display.match(regex);
    if (match) {
      const [_, displayName, type] = match;
      return `@${displayName}`;
    }
    return `@${display}`;
  };

  return (
    <MentionsInput
      aria-multiline
      value={props.value}
      placeholder={props.placeholder}
      onChange={(e) => {
        props.onChange(e, tagInputRef.current);
      }}
      style={defaultStyle}
      inputRef={tagInputRef}
      className={props.className}
    >
      <Mention
        trigger="@"
        data={mentionData}
        displayTransform={displayTransform}
        className="mention"
      />
      <Mention
        trigger="#"
        data={tagData}
        displayTransform={(id, display) => `#${display}`}
        className="mention"
        style={{
          padding: "2px 0",
          fontWeight: "bold",
          color: "fff9",
          backgroundColor: "#CBE3FF",
        }}
      />
    </MentionsInput>
  );
};

export default TagInput;
