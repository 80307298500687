function extractWord(match: string) {
  const result = match.match(/\[\w+\]/);
  if (result?.[0]) {
    const word = result[0].slice(1, -1);
    return `#${word}`;
  }
  return "";
}

export function stripMentionsFormatter(text: string) {
  const output = text.replace(/@\[\w+\]\(\d+\)/g, extractWord);
  return output;
}

export function stripBannedTags(text: string, bannedTags: string[]) {
  bannedTags.forEach((tag) => {
    const regex = new RegExp(tag, "g");
    text = text.replace(regex, "");
  });
  return text;
}

const HASHTAG_REGEX = /#\w+/g;
export function extractHashtags(text?: string) {
  if (!text) return [];

  const hashtags = text.match(HASHTAG_REGEX);
  if (!hashtags) return [];

  return [...new Set(hashtags.map((hashtag: string) => hashtag.slice(1)))];
}

export function hashtagOnCursor(text: string, cursor: number) {
  HASHTAG_REGEX.lastIndex = 0;
  let match: RegExpExecArray | null = HASHTAG_REGEX.exec(text);
  while (match !== null) {
    if (match.index < cursor && cursor <= HASHTAG_REGEX.lastIndex) {
      return match[0].slice(1);
    }
    match = HASHTAG_REGEX.exec(text);
  }
  return null;
}

const MENTION_REGEX =
  /@\[(?<mentionText>.+?) \((?<mentionType>.+?)\)\]\((?<id>\d+)\)/g;

interface Mention {
  text: string;
  type: string;
  id: string;
}

export function extractMentions(text?: string): Mention[] {
  if (!text) return [];

  const matches = text.matchAll(MENTION_REGEX);
  const mentions: Mention[] = [];

  for (const match of matches) {
    if (match.groups) {
      mentions.push({
        text: match.groups.mentionText.trim(),
        type: match.groups.mentionType.toLowerCase(),
        id: match.groups.id,
      });
    }
  }

  const uniqueMentions = Array.from(
    new Set(mentions.map((m) => `${m.text}-${m.type}`)),
  ).map((key) => mentions.find((m) => `${m.text}-${m.type}` === key)!);

  return uniqueMentions;
}

export function mentionOnCursor(text: string, cursor: number) {
  const mentionRegex = /@\w+/g;
  mentionRegex.lastIndex = 0;

  let match: RegExpExecArray | null = mentionRegex.exec(text);
  while (match !== null) {
    if (match.index < cursor && cursor <= mentionRegex.lastIndex) {
      return match[0].slice(1);
    }
    match = mentionRegex.exec(text);
  }

  return null;
}

interface MentionGroups {
  mentionText: string;
  mentionType: string;
  id: string;
}

function extractTag(match: string) {
  const tagResult = match.match(/\[\w+\]/);
  if (tagResult?.[0]) {
    const tag = tagResult[0].slice(1, -1);
    return `#${tag}`;
  }
  return match;
}

function extractMention(match: string) {
  const mentionResult = match.match(
    /@\[(?<mentionText>.+?) \((?<mentionType>.+?)\)\]\((?<id>\d+)\)/,
  );
  const groups = mentionResult?.groups as MentionGroups | undefined;
  if (groups) {
    return `@${groups.mentionText}`;
  }
  return match;
}

export function stripTagsAndMentions(text: string) {
  let output = text.replace(
    /@\[(?<mentionText>.+?) \((?<mentionType>.+?)\)\]\((?<id>\d+)\)/g,
    extractMention,
  );

  output = output.replace(/@\[\w+\]\(\d+\)/g, extractTag);
  return output;
}
